import { notification } from 'antd'
import axios from 'axios'
import { apiUrl, geoCodingToken } from './settings'
import moment from 'moment'
import { store } from './store'
import { getPushPathWrapper } from './routes'
import { push } from 'connected-react-router'

let authAxios = axios.create({
    baseURL: apiUrl
})

export const getToken = () => {
    return {
        headers: {
            Authorization: 'Token ' + localStorage.getItem('token')
        }
        // headers: { Authorization: 'Token ' + 'c38602aa24f9850ac02598cb109ac909b8870fa7' }
    }
}

export const fileUploadUrl = `${apiUrl}/api/file-upload/image/`
export const productImageUrl = `${apiUrl}/media/`
export const paymentUrl = `${apiUrl}/api/order/payment/`

class Request {

    error = err => {
        try {
            if (err.response.status === 401) {
                // localStorage.clear()
                // window.location.reload()
                store.dispatch({ type: 'SET_CURRENT_USER', payload: null })
            }
            if (err.response.status === 403) {
                let state = store.getState()
                let shop = state?.global?.shop
                store.dispatch(shop ?
                    getPushPathWrapper('store', { id: shop?.storeId })
                    : push('/')
                )
                store.dispatch({ type: 'SET_CURRENT_USER', payload: null })
            }
            else if (err.response.status === 429) {
                notification.destroy()
                notification.warning({
                    message: "Too many requests",
                    description: err?.detail
                })
            }
        } catch (e) {
        }
    }

    // ------------------------------------------- API Start from here --------------------------------------------- //

    signup(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/customer/add/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    logout(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/customer/logout/', getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    login(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/customer/login/', data)
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    forgetPassword(data) {
        return new Promise((next, error) => {
            authAxios
                .post('api/customer/forget-password/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    sendOtp(data, params, dispatch) {
       
        return new Promise((next, error) => {
            authAxios
                .post('/api/get-otp/',data)
                .then(d => {
                    if (!d.data.error)
                        notification.success({
                            message: d.data?.message || 'OTP Sent Successfully'
                        })
                    if (d.data?.data?.otp) {
                        let time = moment().add(30, 'seconds').valueOf()
                        dispatch && dispatch({
                            type: 'SET_OTP_TIMER',
                            payload: time
                        })
                        process.env.NODE_ENV === 'development' &&
                            notification.info({ message: 'OTP is ' + d.data?.data?.otp })
                    }
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    verifyOtp(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/verify-otp/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    // user api

    getCustomerProfile(storeId) {
        console.log(getToken(),'getToken()')
        return new Promise((next, error) => {
            authAxios
                .post(`/api/customer/user/`,{}, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    getProfile(storeId) {
        return new Promise((next, error) => {
            authAxios
                .post(`/api/shopperbyid/${storeId}/`,{}, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    updateProfile(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/customer/edit/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    // dashboard api

    getDashboardViewData(data) {
        return new Promise((next, error) => {
            authAxios
                .get('/api/dashboard/', getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    getGraphData(data) {
        return new Promise((next, error) => {
            authAxios
                .get('/api/graph/', { params: data, ...getToken() })
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    // products api

    addProduct(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/product/add/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    getProductById(id, data) {
        return new Promise((next, error) => {
            authAxios
                .post(`/api/product/${id}/`,data)
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    getProducts(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/products/', data )
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    addProductVariant(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/product-variant/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    getProductVariants(data = {}) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/get-product-variant/', { data}, getToken() )
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    deleteProductVariant(id) {
        return new Promise((next, error) => {
            authAxios
                .delete(`/api/product-variant/${id}/`, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    // orders api

    addOrder(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/order/add/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    getOrders(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/order/analytics/',  data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    getOrderById(id) {
        return new Promise((next, error) => {
            authAxios
                .post(`/api/order/${id}/`,{}, getToken())
                .then(d => {
                    next(d.data)
                    console.log(d.data,'web orderDetailsById')
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    updateOrder(id, data) {
        return new Promise((next, error) => {
            authAxios
                .post(`/api/order/status-update/${id}/`, data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }
    // products categories api

    getProductCategory(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/product-category/', data)
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    updateProductCategory(id, data) {
        return new Promise((next, error) => {
            authAxios
                .post(`/api/product-category/${id}/`, data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    // coupons api

    getCoupons(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/get_coupon/',  data )
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }
    validateOrder(data) {
        console.log(data,'validate')
        return new Promise((next, error) => {
            authAxios
                .post('/api/order/validate/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    // address api

    getCustomerAddresses(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/get-customer-address/', { data}, getToken() )
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }
    addCustomerAddress(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/post-customer-address/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }
    deleteCustomerAddress(id) {
        return new Promise((next, error) => {
            authAxios
                .delete(`/api/customer-address/${id}/`, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    addUserNameAndEmail(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/customer/get-name-email/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }
    VerifyEmail(data) {
        let obj={
            email: data
        }
        console.log(obj,'verifydata')

        return new Promise((next, error) => {
            authAxios
                .post('/api/verify/email/cust/', obj, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }
    getUserNameAndEmail() {
        return new Promise((next, error) => {
            authAxios
                .post('/api/customer/get-name-email/',{}, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }
    //Shopkeeper cards
    getStoreData() {
        return new Promise((next, error) => {
            authAxios
                .post(`/api/customer-global/searchbypincode/`,{})
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    getSearchedStoreData(data) {
        let obj = {}
        if (data.search === null) {
            obj = { storeName: data.storeName, explorekey: data.explorekey.toString() }
        }
        else if (data.storeName === null) {
            obj = { search: data.search, explorekey: data.explorekey.toString() }
        }
        else {
            obj = { storeName: data.storeName, search: data.search, explorekey: data.explorekey.toString() }
        }

        return new Promise((next, error) => {
            authAxios
                .post('/api/customer-global/searchbypincode/', obj )
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }
    // Get ViewAll Stores
    getViewAllStoreData(id, address, storeName, page, results, sortShop) {
        let obj = {}
        if (address === "null" && !sortShop) {
            obj = { storeName: storeName, page: page === 0 ? 1 : page, results: results }
        } else if (storeName === "null" && !sortShop) {
            obj = { search: address, page: page === 0 ? 1 : page, results: results }
        } else if (sortShop === "descend") {
            obj = { storeName: storeName, search: address, page: page === 0 ? 1 : page, results: results, sortShop: sortShop }
        } else if (sortShop === "ascend") {
            obj = { storeName: storeName, search: address, page: page === 0 ? 1 : page, results: results }
        } else {
            obj = { storeName: storeName, search: address, page: page === 0 ? 1 : page, results: results }
        }
        return new Promise((next, error) => {
            authAxios
                .post(`/api/get-shopkeeper/${id}/`, obj, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }


    getSuggestionsPincode(data) {
        return new Promise((next, error) => {
            authAxios
                .post(`api/customer/suggestion-pincode-address/`, { search: data })
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    getSuggestionsStoreName(data) {
        return new Promise((next, error) => {
            authAxios
                .post(`api/shopkeeper/suggestion-storeName/`, {storeName: data } )
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    //Reciept

    downlaodReciept(id) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/order/receipt/' + id + '/', { responseType: 'blob' },getToken())
                .then(d => {
                    const blob = new Blob(
                        [d.data], { type: 'application/pdf' })
                    const aEle = document.createElement('a');     // Create a label
                    const href = window.URL.createObjectURL(blob);       // Create downloaded link
                    aEle.href = href;
                    aEle.target = '_blank';
                    aEle.download = `orderId_#${id}_Reciept`;  // File name after download
                    document.body.appendChild(aEle);
                    aEle.click();     // Click to download
                    document.body.removeChild(aEle); // Download complete remove element
                    window.URL.revokeObjectURL(href)
                    next(d.data)

                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    //geocoding api

    getAddress({ lat, lng }) {
        let newObj={
            lat:lat,
            lng:lng
        }
        return new Promise((next, error) => {
            authAxios
                .post(`/api/reverse-geocoding/`,newObj, getToken())
                .then(d => {
                    next(d.data)

                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }
    reportStore(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/report-store/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    reviewRating(data) {
        console.log('contact us', data)
        return new Promise(async (next, error) => {

            authAxios
                .post('/api/customer/rating/reviews/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    adsCampaign(data) {
        console.log('contact us', data)
        return new Promise(async (next, error) => {
            authAxios
                .post('api/customer/campaign-ads/get/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

}



// api/customer/campaign-ads/get/


export default new Request();