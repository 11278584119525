import React, { lazy, useEffect, useState } from 'react'
import { Card, Drawer, List,Rate} from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import HeadTitleLayout from '../../layouts/headTitleLayout'
import _ from 'lodash'
import './styles.less'
import { getPushPathWrapper } from '../../routes'
import { componentLoader, confirmBox } from '../../utils'
import { ReactComponent as LogoutIcon } from '../../assets/logout.svg'
import Media from 'react-media'
import { mobileMaxWidth } from '../../settings'

const SiderMenu = lazy(() => componentLoader(() => import('../../components/siderMenu')))
function Settings(props) {
    const { children, activeKey, title, defaultKey } = props
    const dispatch = useDispatch()
    const MenuArray = [
        {
            title: 'Orders',
            key: 'orders',
            active: true,
            onClick: function () {
                if (defaultKey !== 'orders')
                    dispatch(getPushPathWrapper('orders'))
            }
        },
        {
            title: 'Address Book',
            key: 'addresses',
            onClick: () => {
                if (defaultKey !== 'addresses')
                    dispatch(getPushPathWrapper('addresses'))
            }
        },
        {
            title: 'Help & Support',
            key: 'helpAndSupport',
            onClick: () => {
                if (defaultKey !== 'helpAndSupport')
                    dispatch(getPushPathWrapper('helpAndSupport'))
            }
        },
        {
            title: 'Privacy Policy',
            key: 'privacyPolicy',
            onClick: () => {
                if (defaultKey !== 'privacyPolicy')
                    dispatch(getPushPathWrapper('privacyPolicy'))
            }
        },
        {
            title: 'Report Store',
            key: 'feedback',
            onClick: () => {
                if (defaultKey !== 'feedback')
                    dispatch(getPushPathWrapper('feedback'))
            }
        },
        {
            title: 'Log Out',
            key: 'logout',
            icon: <LogoutIcon />
        }
    ]
    const [state, setState] = useState({
        categories: MenuArray,
        count: 0,

    })
    const [rate, setRate]= useState([]);
    console.log(rate,'ratesetting')
    const { currentUser, shop, collapsed } = useSelector((state) => ({
        currentUser: state.global.currentUser,
        shop: state.global.shop,
        collapsed: state.theme.collapsed
    }))
    console.log(currentUser,'currentuser')
    useEffect(() => {
        if (!currentUser) {
            shop ? dispatch(getPushPathWrapper('store', { id: shop?.storeId }))
                :
                dispatch(getPushPathWrapper('login'))
        }
    }, [currentUser])
    // useEffect(() => {
    //     if (activeKey > -1 && !isDesktop) {
    //         onActiveChange(activeKey)
    //     }
    // }, [activeKey])
    const onActiveChange = (index) => {
        let newData = _.map(MenuArray, (e) => {
            e.active = false;
            return e
        })
        newData[index].onClick()
    }
    const setCount = (val) => {
        setState((prev) => ({
            ...prev,
            count: val
        }))
    }
    const { categories, count } = state
    const childrenWithProps = React.cloneElement(children, { setCount })
    return (<div className='flexColumn' style={{ position: 'relative', height: '100%', justifyContent: 'start' }}>
        <HeadTitleLayout title={`${title} ${count ? `(${count})` : ''}`} isBack={true} />
        <div id='settingsWrapper'>
            <div className="orderTitle">
                <Media queries={{ small: { maxWidth: mobileMaxWidth } }}>
                    {matches =>
                        matches.small ? (
                            <Drawer
                                width={200}
                                visible={!collapsed}
                                placement="left"
                                onClose={() => {
                                    dispatch({ type: 'TOGGLE_SIDEBAR_COLLAPSED' })
                                }}
                                closable={false}
                                getContainer={false}
                                bodyStyle={{
                                    padding: 0,
                                    height: '100vh'
                                }}
                            >
                                <SiderMenu data={categories} activeKey={activeKey} onActiveChange={onActiveChange} />
                                <></>
                            </Drawer>) :
                            <Card className="ordersMenu" title={
                            <>
                            <div style={{ fontSize: '20px', fontWeight: 600 }}>Account </div>
                            {
                                currentUser?.customer_rating_overall ?
                                <div> 
                                <Rate value={currentUser?.customer_rating_overall}  style={{fontSize:'1.1rem'}} disabled={true}/>&nbsp; {currentUser?.customer_rating_overall}/5
                                </div>
                                : null
                            } 
                         
                            </>
                            } >
                                <List
                                    size="small"
                                    dataSource={categories}
                                    renderItem={(item, i) => (
                                        <List.Item
                                            key={item.key}
                                            onClick={() =>
                                                item?.key !== 'logout' ?
                                                    onActiveChange(i) :
                                                    confirmBox(dispatch, {
                                                        message: 'Are you sure you want to Logout',
                                                        onOk: () => {
                                                            dispatch({ type: 'LOGOUT' })
                                                        }
                                                    })
                                            }
                                            className={`categoryListItem ${activeKey === i && 'categoryListItemSelected'}`}
                                        >

                                            <List.Item.Meta title={item.title}></List.Item.Meta>
                                        </List.Item>
                                    )}
                                />

                            </Card>


                    }
                </Media>


            </div>
            <div className='ordersListWrapper' >
                {childrenWithProps}
            </div>
        </div>
    </div>
    )
}

export default Settings
