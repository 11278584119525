import request from "../request"
import moment from 'moment'
import { isEqual } from "lodash"
const initialState = {
  shop: null,
  currentUser: null,
  mobileNo: null,
  token: null,
  otpTimer: moment().subtract(1, 'minute').valueOf(),
  cancelOrder: null,
}

export default (state = initialState, action) => {
  switch (action.type) {

    case 'SET_CURRENT_USER':
      if (isEqual(state.currentUser, action.payload)) {
        return state
      }
      else
        return {
          ...state,
          currentUser: action.payload
        }
    case 'SET_CURRENT_SHOP':
      if (isEqual(state.shop, action.payload)) {
        return state
      }
      else
        return {
          ...state,
          shop: action.payload
        }

    case 'SET_MOBILE':
      return {
        ...state,
        mobileNo: action.payload.mobile,
        name: action.payload.name,
        email: action.payload.email,
      }
      
    case 'LOGOUT':
      request.logout()
      return {
        ...state,
        currentUser: null,
        token: null
      }

    case 'SET_AUTH_TOKEN':
      window.localStorage.setItem('token', action.payload)
      return {
        ...state,
        token: action.payload
      }

    case 'SET_OTP_TIMER':
      return {
        ...state,
        otpTimer: action.payload
      }
    case 'SET_CANCEL_TIMER':
      return {
        ...state,
        cancelOrder: action.payload
      }
      case 'SOCKET_REFRESH':
      return {
        ...state,
        socketRefresh: action.payload
      }
    default:
      return state
  }
}
