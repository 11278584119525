import React, { useState } from 'react'
import { Typography, Menu, Badge, Image } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import './styles.less'
import { ReactComponent as BagIcon } from '../../assets/bagIcon.svg'
import { useDispatch, useSelector } from 'react-redux';
import { getPushPathWrapper } from '../../routes';
import { ReactComponent as CallIcon } from '../../assets/call.svg';
import QuickseHeaderLogo from '../../assets/quickSeLogo.svg'
import { productImageUrl } from '../../request';
import { useHistory } from 'react-router';
const { Text } = Typography;
const { SubMenu } = Menu;

const HomeHeader = (props) => {
    const { isMobile, isDesktop, item } = props
    const dispatch = useDispatch()
    const { currentUser, cartLength, shop } = useSelector((state) => ({
        currentUser: state.global.currentUser,
        shop: state.global.shop,
        cartLength: state.cart.cartLength
    }))
    const history = useHistory()
    const goToStore = () => {
        if (shop && item.key !== 'store') {
            dispatch(getPushPathWrapper('store', { id: shop.storeId }))
        }
    }
    return (<div className={'header flex'}>
        <div className={'headText'}>
            {(!isMobile) ? <span className='desktop'>
                <div className='flex'>
                    {shop?.photoUrl && <Image
                        id='headShopImage'
                        width={50}
                        height={50}
                        preview={false}
                        style={{ borderRadius: '10px' }}
                        src={productImageUrl + shop?.photoUrl}
                    />}
                    <div style={{ paddingLeft: '0.5rem' }}>
                        <div className={'heading cursorPointer'} style={{ alignItems: 'center' }} onClick={goToStore}>
                            {/* {`${shop?.storeName ? shop?.storeName : }`} */}
                            {shop?.storeName ? shop.storeName : (
                                <div onClick={() => history.push('/')}>
                                    <img src={QuickseHeaderLogo} alt="logo" width={150} style={{ marginTop: '12px' }} />
                                </div>
                            )}
                        </div>
                        <Text
                            type='secondary'
                            className='flexColumn'
                            style={{ justifyContent: 'flex-start', marginTop: '0.2rem' }}
                        >
                            <div className='overflowAddress' style={{ lineHeight: '17px' }}>
                                <span style={{ marginRight: '0.5rem' }}>
                                    {shop?.addressId?.locality}
                                </span>
                                <span style={{ marginRight: '0.5rem' }}>
                                    {shop?.addressId?.pinCode}
                                </span>
                            </div>
                            <div style={{ lineHeight: '17px' }}>
                                {shop?.mobile && (
                                    <>
                                        <CallIcon width={16} style={{ marginRight: '0.2rem' }} />
                                        {shop?.mobile}
                                    </>
                                )}
                            </div>
                        </Text>
                    </div>
                </div>
            </span> :
                <MenuOutlined onClick={() => {
                    dispatch({ type: 'TOGGLE_SIDEBAR_COLLAPSED' })
                }} />}
            {/* <div className={'heading cursorPointer'} onClick={goToStore}>{`${shop?.storeName || 'StoreName'}`}</div>
            <Text type='secondary'>{currentUser?.tagLine}</Text> */}
        </div>
        <div className={'headTools flex'}>
            <div style={{ fontWeight: 'bold' }}>
                {`Delivery: 11AM to 11PM`}
            </div>
            <div className={'cursorPointer'} style={{ margin: '0 1rem', display: 'flex' }} onClick={() => {
                isDesktop ?
                    dispatch({ type: 'TOGGLE_CART_VIEW' }) :
                    goToStore()
            }} >
                <Badge count={cartLength || 0}>
                    <div style={{ marginBottom: '-10px' }}><BagIcon /></div>
                </Badge>
                <div style={{ marginLeft: '10px' }}>{isMobile ? '' : 'Cart'}</div>

            </div>

            <div className='cursorPointer primaryTextColor' onClick={() => {
                if (isMobile && currentUser) {
                    dispatch({ type: 'TOGGLE_SIDEBAR_COLLAPSED' })
                }
                else if (currentUser)
                    dispatch(getPushPathWrapper('orders'))
                else
                    dispatch(getPushPathWrapper('login'))
            }}>
                {`Account`}
            </div>
            {/* <Avatar className='cursorPointer' onClick={() => {
                if (currentUser)
                    dispatch(getPushPathWrapper('orders'))
                else
                    dispatch(getPushPathWrapper('login'))
            }} size='large' src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" /> */}
        </div>
    </div>
    )
}
export default HomeHeader