import React, { lazy } from 'react'
import _ from 'lodash'
import { Path } from 'path-parser'
import { push } from 'connected-react-router'
import SettingWrapper from './containers/settings'
import { componentLoader } from './utils'

const Products = lazy(() => componentLoader(() => import('./containers/products')))
const Orders = lazy(() => componentLoader(() => import('./containers/settings/orderList')))
const Addresses = lazy(() => componentLoader(() => import('./containers/address')))
const ReportShop = lazy(() => componentLoader(() => import('./containers/report')))
const FAQS = lazy(() => componentLoader(() => import('./containers/faqAndPrivcay/helpSetting')))
const PrivacyPolicy = lazy(() => componentLoader(() => import('./containers/faqAndPrivcay/privacyPolicy')))
const Checkout = lazy(() => componentLoader(() => import('./containers/checkout')))
const OrdersDetails = lazy(() => componentLoader(() => import('./containers/settings/orderDetails')))
const Login = lazy(() => componentLoader(() => import('./containers/authentication')))
const Review = lazy(() => componentLoader(() => import('./containers/rating&review')))

const menu = [

 
   {
    'path': '/orders',
    'name': 'Orders',
    'icon': <></>,
    'key': 'orders',
    'component': <SettingWrapper defaultKey='orders' title='Your Orders' activeKey={0}><Orders /></SettingWrapper>,
    'authority': [
      'user'
    ]
  },
  {
    'path': '/review',
    'name': 'review',
    'icon': <></>,
    'key': 'review',
    'component': <SettingWrapper defaultKey='orders' title='Your Orders' activeKey={0}><Review /></SettingWrapper>,
    'authority': [
      'user'
    ]
  },
  {
    'path': '/addresses',
    'name': 'Addresses',
    'icon': <></>,
    'key': 'addresses',
    'component': <SettingWrapper defaultKey='addresses' title='Address Book' activeKey={1}>
      <Addresses forSettings={true} />
    </SettingWrapper>,
    'authority': [
      'user'
    ]
  },
  {
    'path': '/helpAndSupport',
    'name': 'Help & Support',
    'icon': <></>,
    'key': 'helpAndSupport',
    'component': <SettingWrapper defaultKey='helpAndSupport' title='Help & Support' activeKey={2}><FAQS /></SettingWrapper>,
    'authority': [
      'user'
    ]
  },
  {
    'path': '/privacyPolicy',
    'name': 'Privacy Policy',
    'icon': <></>,
    'key': 'privacyPolicy',
    'component': <SettingWrapper defaultKey='privacyPolicy' title='Privacy Policy' activeKey={3}><PrivacyPolicy /></SettingWrapper>,
    'authority': [
      'user'
    ]
  },
  {
    'path': '/report',
    'name': 'feedback',
    'icon': <></>,
    'key': 'feedback',
    'component': <SettingWrapper defaultKey='feedback' title='Report Store' activeKey={4}><ReportShop forSettings={true} /></SettingWrapper>,
    'authority': [
      'user'
    ]
  },
  {
    'path': '/checkout',
    'name': 'Checkout',
    'icon': <></>,
    'key': 'checkout',
    'component': <Checkout />,
    'authority': [
      'user'
    ]
  },
  {
    'path': '/ordersDetails/:id',
    'name': 'Order Details',
    'icon': <></>,
    'key': 'ordersDetails',
    'component': <OrdersDetails />,
    'authority': [
      'user'
    ]
  },
  {
    'path': '/login',
    'name': 'Login',
    'icon': <></>,
    'key': 'login',
    'component': <Login />,
    'authority': [
      'user'
    ]
  },
  {
    'path': '/:id/',
    'name': 'Store',
    'icon': <></>,
    'key': 'store',
    'component': <Products />,
    'authority': [
      'user'
    ]
  },
  {
    'path': '/:id/:product',
    'name': 'Store',
    'icon': <></>,
    'key': 'product',
    'component': <Products />,
    'authority': [
      'user'
    ]
  }
]

// utils for path

export const getUrlPushWrapper = (keyString, query) => {
  return push(getUrlPath(keyString, query))
}

export const getUrlPath = (keyString, params) => {

  if (!params) params = {}

  let keyArr = keyString.split('.')

  let val = _.find(menu, p => p.key === keyArr[0])

  if (!val) {
    return `/`
  }

  if (keyArr.length === 2) {
    val = _.find(val.children, p => p.key === keyArr[1])
  }

  if (!val) {
    return `/`
  }

  let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&')

  return `${val.path}?${queryString}`
}

export const getPushPathWrapper = (keyString, params = {}) => {

  let obj = getUrlObject(keyString)

  if (obj) {
    const path = new Path(obj.path)
    return push(path.build(params, { urlParamsEncoding: 'none' }))
  }

  return 'error'
}

export const getUrlParams = (keyString, route) => {

  let obj = getUrlObject(keyString)
  if (obj) {
    const path = new Path(obj.path)

    return path.test(route)
  }

  return { error: true }
}

export const getUrlObject = (keyString) => {

  let keyArr = keyString.split('.')

  let val = _.find(menu, p => p.key === keyArr[0])

  if (!val) {
    return `/`
  }

  if (keyArr.length === 2) {
    val = _.find(val.children, p => p.key === keyArr[1])
  }

  if (!val) {
    return `/`
  }

  return val
}


export default menu;