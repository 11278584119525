import React, { useEffect, useState } from 'react'
import { Layout, Menu, Typography, Drawer } from 'antd';
import './styles.less'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux';
import { confirmBox, goToStore } from '../../utils';
import { MenuArray } from '../../utils/dropValues';
import { getPushPathWrapper } from '../../routes';
import { ReactComponent as CallIcon } from '../../assets/call.svg';
import QuickseHeaderLogo from '../../assets/quickseHeaderLogo.svg'
import { useHistory } from 'react-router';
const { Sider } = Layout;
const { Text } = Typography;


export default (props) => {
    const dispatch = useDispatch()

    const { item } = props
    const [selected, setSelected] = useState(false)
    const { collapsed, currentUser, shop } = useSelector((state) => ({
        collapsed: state.theme.collapsed,
        currentUser: state.global.currentUser,
        shop: state.global.shop,
    }))
    const history = useHistory()
    useEffect(() => {
        if (MenuArray) {
            _.forEach(MenuArray, (cat, i) => {
                if (item?.key === cat?.key)
                    setSelected(() => cat.key)
            })
        }
    }, [MenuArray])

    return (
        <Drawer
            className='drawer'
            width={'60%'}
            visible={!collapsed}
            placement="left"
            onClose={() => {
                dispatch({ type: 'TOGGLE_SIDEBAR_COLLAPSED' })
            }}
            closable={false}
            getContainer={false}
            bodyStyle={{
                padding: 0,
                height: '100vh'
            }}
        >
            <Sider
                className='sider'
                trigger={null}
                collapsedWidth={0}
                style={{ height: '100%' }}
                collapsible
                collapsed={collapsed}
            >

                <div className={'namespace'}>
                    <div className={'headText'} onClick={() => goToStore(shop, dispatch)}>
                        <div className={'heading cursorPointer'} style={{ fontSize: '16px', marginBottom: 0, marginRight: '1rem' }} >
                            {/* {`${shop?.storeName || 'StoreName'}`} */}
                            {shop?.storeName ? shop.storeName : (
                                <div onClick={() => history.push('/')}>
                                    <img src={QuickseHeaderLogo} alt="logo" width={150} style={{ marginTop: '12px' }} />
                                </div>
                            )}
                        </div>
                        <Text
                            style={{ fontSize: '14px', justifyContent: 'flex-start' }}
                            type='secondary'
                            className='flexColumn'
                        >
                            <span style={{ marginRight: '0.5rem' }}>
                                <span style={{ marginRight: '0.5rem' }}>{shop?.addressId?.locality}</span>
                                <span>{shop?.addressId?.pinCode}</span>
                            </span>
                            {
                                shop?.mobile && (
                                    <span>
                                        <CallIcon width={14} style={{ marginRight: '0.2rem' }} />
                                        {shop?.mobile}
                                    </span>
                                )
                            }
                        </Text>
                    </div>
                </div>
                <Menu theme='dark' mode='inline'
                    selectedKeys={[selected]}
                    className={'menu'}
                >
                    {(!currentUser ? [{
                        title: 'Log in',
                        key: 'login'
                    }] : MenuArray).map((elem, i) => {
                        return (
                            <Menu.Item
                                key={elem.key}
                                title={elem.title}
                                className={`menuItem ${elem.key === selected && 'selectedMenu'}`}
                                onClick={() => {
                                    elem.key === 'logout' ?
                                        currentUser ?
                                            confirmBox(dispatch, {
                                                message: 'Are you sure you want to Logout',
                                                onOk: () => {
                                                    dispatch({ type: 'LOGOUT' })
                                                }
                                            }) :
                                            dispatch(getPushPathWrapper('login'))
                                        :
                                        dispatch(getPushPathWrapper(elem.key))

                                    dispatch({ type: 'TOGGLE_SIDEBAR_COLLAPSED' })
                                }}
                            >
                                {/* {item.icon} */}
                                <span>{elem.title}</span>
                            </Menu.Item>
                        )
                    })}
                </Menu>
            </Sider>
        </Drawer>
    );
}