import { clone, findIndex } from "lodash";
import { ReactComponent as LogoutIcon } from '../assets/logout.svg'

let errorStatuses = ['REJECTED', "FAILED", "CANCELLED"]
export const statuses = [
    {
        label: 'All',
        value: 'all'
    },
    {
        label: 'Pending',
        value: "pending"
    },
    {
        label: 'Accepted',
        value: "accepted"
    },
    {
        label: 'Rejected',
        value: "rejected"
    },
    {
        label: 'Shipped',
        value: "shipped"
    },
    {
        label: 'Cancelled',
        value: "cancelled"
    },
    {
        label: 'Delivered',
        value: "delivered"
    },
    {
        label: 'Failed',
        value: "failed"
    },
    {
        label: 'Declined',
        value: "declined"
    },
    {
        label: 'Payment Failed',
        value: "Payment Failed"
    },
    {
        label: 'Returned',
        value: "returned"
    }
];
export const MenuArray = [
    {
        title: 'Orders',
        key: 'orders',
        active: true
    },
    {
        title: 'Address Book',
        key: 'addresses'
    },
    {
        title: 'Help & Support',
        key: 'helpAndSupport'
    },
    {
        title: 'Privacy Policy',
        key: 'privacyPolicy'
    },
    {
        title: 'Report Store',
        key: 'feedback'
    },
    {
        title: 'Log Out',
        key: 'logout',
        icon: <LogoutIcon />
    }
]

export const cancelationReasons = [
    'Order created by mistake',
    'Products would not arrive on time',
    'Shipping cost too high',
    'Product price cost too high',
    'Need to change delivery address',
    'Other'
]
