
import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { getPushPathWrapper } from "../routes";
import { apiUrl } from '../settings'
export const businessCatImagePathUrl = (path) => `${apiUrl}/media/${path}`



export function isNormalInteger(str) {
    var n = Math.floor(Number(str));
    return n !== Infinity && String(n) === str && n >= 0;
}
export function passwordValidator({ getFieldValue }) {
    return ({
        validator(_, value) {
            if (!value || isNormalInteger(value)) {
                return Promise.resolve();
            }
            return Promise.reject('Pin must be number');
        },
    })
}
export function mobileValidator({ getFieldValue }) {
    return ({
        validator(_, value) {
            if (!value || isNormalInteger(value)) {
                return Promise.resolve();
            }
            return Promise.reject('Invalid Mobile no.');
        },
    })
}
export function pinCodeValidator({ getFieldValue }) {
    return ({
        validator(_, value) {
            if (!value || parseInt(value).toString().length === 6) {
                return Promise.resolve();
            }
            return Promise.reject('Invalid Pin Code');
        },
    })
}

export function confirmBox(dispatch, { onOk, message, okText, cancelText, title, icon, ...rest }) {
    Modal.confirm({
        onOk: () => {
            onOk && onOk()
        },
        centered: true,
        content: message,
        title: title || 'Confirm',
        icon: icon || <ExclamationCircleOutlined />,
        okText: okText || 'Yes',
        cancelText: cancelText || 'No',
        ...rest
    });
}
export function getPercentage(mrp, sellingPrice) {
    let percentValue = 100 - sellingPrice / (mrp / 100)
    percentValue = parseFloat(percentValue)
    percentValue = isNaN(percentValue) ? 0 : percentValue.toFixed(1)
    return percentValue;
}
export function goToStore(shop, dispatch) {
    dispatch({ type: 'TOGGLE_SIDEBAR_COLLAPSED' })

    if (shop) {
        dispatch(getPushPathWrapper('store', { id: shop.storeId }))
    }
}
export function getVariants(variantArr) {
    let defaultVariant = variantArr[0]
    let variants = variantArr.filter((item) => item.variantType !== '')
    return {
        defaultVariant,
        variants
    }
}

// component loader

export function componentLoader(lazyComponent, attemptsLeft = 2) {
    return new Promise((resolve, reject) => {
        lazyComponent()
            .then(resolve)
            .catch((error) => {
                // let us retry after 100 ms
                setTimeout(() => {
                    if (attemptsLeft === 1) {
                        reject(error);
                        return;
                    }
                    componentLoader(lazyComponent, attemptsLeft - 1).then(resolve, reject);
                }, 100);
            });
    });
}

export const alphaNumericRegex = /^[a-z0-9 ]+$/i
export const emailValidateRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const alphaNumericCapsRegex = /^[A-Z0-9]+$/
export const alphaNumericWithCommasRegex = /^[a-z0-9,\- \/()]+$/i