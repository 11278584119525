import React, { lazy, Suspense, useEffect } from "react";
import { Route, Redirect, Switch, useParams } from "react-router-dom";
import menuData from "../../routes";
import BasicLayout from "../../layouts/basicLayout";
import { Spin } from "antd";
import { componentLoader } from "../../utils";
import { BrowserView, MobileView, CustomView } from 'react-device-detect';


const StoreList = lazy(() =>
  componentLoader(() =>
    import("../../components/searchPinCode/StoreList/index")
  )
);
const SearchPinCode = lazy(() =>
  componentLoader(() => import("../../components/searchPinCode/index"))
);
const NotFound = lazy(() =>
  componentLoader(() => import("../../components/Exception/404"))
);
const HomePage = lazy(() =>
  componentLoader(() => import("../../components/searchPinCode/homePage/index"))
);

const MobileHomePage = lazy(() =>
  componentLoader(() =>
    import("../../components/searchPinCode/MobileHome/index")
  )
);
const Exp = () => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
    }}
  >
    <NotFound />
  </div>
);
export default (props) => {
  const isTablet= window.screen > 414 
  return (
    <Suspense
      fallback={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <Spin size="large" />{" "}
        </div>
      }
    >
      <Switch>
        <Route exact path={"/stores"} render={(route) => <SearchPinCode />} />
        <Route
          exact
          path={"/storelist/:id/:name"}
          render={(route) => <StoreList />}
        />
        {menuData.map((item, key) => {
          if (!item.children) {
            return (
              <Route
                exact
                path={item.path}
                key={item.path}
                render={(route) => {
                  return (
                    <BasicLayout
                      path={item.path}
                      item={item}
                      menuData={menuData}
                    >
                      {!!item.component ? (
                        <Suspense
                          fallback={
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "100vh",
                              }}
                            >
                              <Spin size="large" />{" "}
                            </div>
                          }
                        >
                          {item.component}
                        </Suspense>
                      ) : (
                        <></>
                      )}
                    </BasicLayout>
                  );
                }}
              />
            );
          }
        })}

        {menuData.map((item, key) => {
          if (item.children) {
            return item.children.map((child, k) => {
              return (
                <Route
                  exact
                  path={child.path}
                  key={child.path}
                  render={(route) => {
                    return (
                      <BasicLayout
                        path={child.path}
                        menuData={menuData}
                        item={item}
                      >
                        {!!child.component ? (
                          <Suspense
                            fallback={
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  height: "100vh",
                                }}
                              >
                                <Spin size="large" />{" "}
                              </div>
                            }
                          >
                            {child.component}
                          </Suspense>
                        ) : (
                          <></>
                        )}
                      </BasicLayout>
                    );
                  }}
                />
              );
            });
          }
        })}

        <Route
          exact
          path={"/"}
          render={(route) => (
            <div>
              <BrowserView >
                <HomePage />
              </BrowserView>
              <MobileView>
                <MobileHomePage />
              </MobileView>
              <CustomView condition={isTablet}>
              <HomePage />
              </CustomView>
            
            </div>
          )}
        />
        <Route render={(route) => <Exp />} />
      </Switch>
    </Suspense>
  );
};
