import { message, notification } from "antd"
import { cloneDeep, findIndex, find, sumBy } from "lodash"
import { useDispatch, useSelector } from 'react-redux';

import request from "../request"

const initialState = {
    cartItems: [],
    subTotal: null,
    grandTotal: null,
    deliveryCharges: 0,
    discount: 0,
    cartLength: 0,
    couponId: null,
    addressId: null,
    isAddressValid: true,
    validationLoader: false,
    refundCheck: false,
    convenienceFee: 0,
    pickupstatus: false,
    tableNo:null,
    validateAddress:null

}

async function calculateAjax(state, shop, dispatch) {
   
    const { cartItems, couponId, addressId, pickupstatus,tableNo } = state
    if (!shop?.user_id || !addressId) {
        return {}
    }
    console.log(addressId,pickupstatus,'addressIddddd')
    
    const orderDetails = {
        pickupstatus:pickupstatus ? pickupstatus.toString() :true.toString(),
        addressId:addressId,
        shopkeeperId: shop?.user_id,
        orderTable: tableNo,
        orderItems: [
            ...cartItems.map((e) => ({
                "productVariantId": e.id,
                "productName": e.productName,
                "quantity": e.cartQuantity,
                "color": e.color
            }))
        ]

    }
    if (couponId) {
        orderDetails.couponId = couponId
    }
    setTimeout(() => {
        dispatch({ type: 'SET_VALIDATION_LOADER_TRUE' })
    }, 0);
    const { error, data, message: respMessage } = await request.validateOrder(orderDetails)
    console.log(error,respMessage,'Javeddd')
    setTimeout(() => {
        dispatch({ type: 'SET_VALIDATION_LOADER_FALSE' })
    }, 0);
    if (!error && data) {
        message.destroy()
        notification.destroy()
        if (addressId)
            message.info('Applied charges are updated.')

        return {
            subTotal: data.itemTotal,
            grandTotal: data.grandTotal,
            deliveryCharges: data.deliveryCharges,
            convenienceFee: data.convenienceFee,
            isAddressValid: true,
            discount: data?.discount || 0,
            cartItems: cartItems.map((item) => {
                let product = find(data?.orderItems,
                    (prodItem) => (prodItem?.productVariantId?.id === item.id && prodItem?.productVariantId?.productId?.id === item.productId))
                if (product) {
                    if (item.sellingPrice !== product.sellingPrice) {
                        message.destroy()
                        message.warning(`price of ${product.productVariantId?.productId?.productName} is changed.`, 30,)
                    }
                    item.sellingPrice = product.sellingPrice
                    item.unitQuantity = product.unitQuantity
                    item.variantType = product.variantType
                    item.variantValue = product.variantValue
                    if (product?.productVariantId?.productId?.productName)
                        item.productName = product.productVariantId?.productId?.productName
                }
                return item
            })
        }
    }
    else {
        if (respMessage?.toLowerCase()?.includes('within a distance')) {
            notification.destroy()
            notification.error({
                message: respMessage || 'Something Went Wrong'
            })
            return {
                convenienceFee: null,
                deliveryCharges: null,
                isAddressValid: false
            }
        }
        else if (respMessage?.toLowerCase()?.includes('coupon')) {
            return {
                convenienceFee: data.convenienceFee,
                subTotal: data.itemTotal,
                grandTotal: data.grandTotal,
                deliveryCharges: data.deliveryCharges,
                couponId: null,
                discount: 0,
                // cartItems: cartItems.map((item) => {
                //     let product = find(data?.orderItems, (prodItem) => prodItem?.productId?.productId === item.productId)
                //     if (product) {
                //         item.sellingPrice = product.sellingPrice
                //     }
                //     return item
                // })
            }
        }
        else if (respMessage?.toLowerCase()?.includes('product')) {
            notification.destroy()
            notification.error({
                message: respMessage,
                description: 'Please try to remove the product from cart then try again.'
            })
            return {
                ...state
            }
        }
        else {
            notification.destroy()
            notification.error({
                message: respMessage || 'Something Went Wrong'
            })
            return { isAddressValid: false }
        }
    }

}
function calculate(data) {
    const { cartItems, deliveryCharges, convenienceFee } = data
    let subTotal = sumBy(cartItems, (e) => e.sellingPrice * e.cartQuantity)
    let grandTotal = subTotal + deliveryCharges + convenienceFee
    return {
        subTotal: subTotal,
        grandTotal: grandTotal
    }
}
function addItemInCart(state, action) {
    let cartItems = cloneDeep(state.cartItems) || []
    let newData = cloneDeep(action.payload)
    let index = findIndex(cartItems, (item) => (item.id === newData.id && item.color === newData.color))
    if (index > -1) {
        if (newData.newQuantity) {
            newData.cartQuantity = newData.newQuantity
            delete newData.newQuantity
        }
        else {
            newData = cartItems[index]
            newData.cartQuantity += 1
        }
        if (newData.quantity < newData.cartQuantity) {
            notification.destroy()
            notification.warning({ message: 'Quantity exceeded.' })
            return state
        }
        cartItems.splice(index, 1, newData)
    } else {
        newData.cartQuantity = newData.newQuantity || 1
        if (newData.quantity < newData.cartQuantity) {
            notification.destroy()
            notification.warning({ message: 'Quantity exceeded.' })
            return state
        }
        cartItems.push(newData)
    }
    return {
        cartItems: cartItems,
        cartLength: sumBy(cartItems, 'cartQuantity')
    }
}
function removeItemFromCart(state, action) {
    let cartItems = cloneDeep(state.cartItems) || []
    let newData = cloneDeep(action.payload)
    let index = findIndex(cartItems, (item) => (item.id === newData.id && item.color === newData.color))
    let newQty = cartItems[index].cartQuantity - 1
    if (newQty) {
        // cartItems.splice(index, 1, { ...cartItems[index], cartQuantity: newQty })
        cartItems[index].cartQuantity = newQty
    } else {
        cartItems.splice(index, 1)
    }
    return {
        cartItems: cartItems,
        cartLength: sumBy(cartItems, 'cartQuantity')
    }
}
function addProduct(state, action) {
    const { cartItems, cartLength } = addItemInCart(state, action)
    return {
        ...state,
        cartItems,
        cartLength,
        ...calculate({ ...state, cartItems })
    }
}
function removeProduct(state, action) {
    const { cartItems, cartLength } = removeItemFromCart(state, action)
    return {
        ...state,
        cartItems,
        cartLength,
        ...calculate({ ...state, cartItems })
    }
}
function deleteProduct(state, action) {
    let cartItems = cloneDeep(state.cartItems) || []
    let newData = cloneDeep(action.payload)
    let index = findIndex(cartItems, (item) => item.productId === newData.productId)
    if (index > -1)
        cartItems.splice(index, 1)
    return {
        ...state,
        cartItems,
        cartLength: sumBy(cartItems, 'cartQuantity'),
        ...calculate({ ...state })
    }
}
function addProductAjax(state, action, refresh = false) {
    const { cartItems, cartLength } = refresh ? state : addItemInCart(state, action)
    calculateAjax({ ...state, cartItems }, action.shop, action.dispatch).then((resp) => {
        action.dispatch({
            type: "SET_CART", payload: {
                ...state,
                cartItems,
                cartLength,
                ...resp
            }
        })
    })
}
function removeProductAjax(state, action) {
    const { cartItems, cartLength } = removeItemFromCart(state, action)
    calculateAjax({ ...state, cartItems }, action.shop, action.dispatch)
        .then((resp) => {
            action.dispatch({
                type: "SET_CART", payload: {
                    ...state,
                    cartItems,
                    cartLength,
                    ...resp
                }
            })
        })
}
export default (state = initialState, action) => {
    switch (action.type) {

        case 'SET_ADDRESS':
            return {
                ...state,
                addressId: action.payload
            }
        case 'SET_VALIDATION_LOADER_TRUE':
            return {
                ...state,
                validationLoader: true
            }
        case 'SET_VALIDATION_LOADER_FALSE':
            return {
                ...state,
                validationLoader: false
            }
        case 'ADD_PRODUCT_TO_CART':
            return addProduct(state, action)

        case 'REMOVE_PRODUCT_FROM_CART':
            return removeProduct(state, action)
        case 'DELETE_PRODUCT_FROM_CART':
            return deleteProduct(state, action)

        case 'ADD_PRODUCT_TO_CART_WITH_CALC':
            addProductAjax(state, action)
            return state

        case 'REFRESH_CART':
            addProductAjax(state, action, true)
            return state

        case 'REMOVE_PRODUCT_FROM_CART_WITH_CALC':
            removeProductAjax(state, action)
            return state

        case 'SET_CART':
            return {
                ...state,
                ...action.payload
            }
        case 'SET_COUPON':
            return {
                ...state,
                couponId: action.payload
            }

        case 'CLEAR_CART':
            return {
                ...state,
                cartItems: [],
                subTotal: 0,
                grandTotal: 0,
                cartLength: 0
            }

        case 'SET_DELIVERY_CHARGES':
            return {
                ...state,
                deliveryCharges: action.payload
            }
        case 'SET_CONVINIENCE_CHARGES':
            console.log(action.payload,'conviniencefeePayload')
            return {
                ...state,
                convenienceFee: action.payload
            }
        case 'SET_REFUND_CHECK':
            return {
                ...state,
                refundCheck: action.payload
            }
        case 'SET_PICKUP_STATUS':
            return {
                ...state,
                pickupstatus: action.payload
            }
            case 'SET_CURRENT_TABLE':
                return {
                  ...state,
                  tableNo: action.payload,
                 
                }
                case 'SET_VALIDATE_ADDRESS':
                    return {
                      ...state,
                      validateAddress: action.payload,
                     
                    }
        default:
            return state
    }
}
