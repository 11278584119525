export const apiUrl = process.env.REACT_APP_API_URL
export const socketUrl = process.env.REACT_APP_SOCKET_URL
export const mapKey = process.env.REACT_APP_MAP_API_KEY
// export const apiUrl = 'https://api.quickse.in'
// export const socketUrl = 'wss://api.quickse.in/ws/consumer'

export const fbAppId = '687988341580190'
export const FilterDateFormat = 'YYYY-MM-DD'
export const DateTimeFormat = 'DD MMMM YYYY HH:mm'
export const DateFormat = 'DD MMMM YYYY'
export const RupeeSymbol = '₹'
export const desktopMaxWidth = 1100
export const mobileMaxWidth = 800
export const geoCodingToken = 'pk.c1a8fcdc010c16759c1385981f25bc94'