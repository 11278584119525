import { combineReducers } from 'redux'
import global from './global'
import theme from './theme'
import cart from './cart'
import notification from './notification'
import { connectRouter, routerMiddleware } from 'connected-react-router'

export default (history) => combineReducers({
  theme,
  global,
  cart,
  notification,
  router: connectRouter(history)
})
