import React, { useState, useEffect, lazy } from 'react'
import _ from 'lodash'
import { Layout } from 'antd';
import './basicLayout.less'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import DashboardHeader from '../components/header';
import { useParams } from 'react-router';
import request from '../request';
import { desktopMaxWidth, mobileMaxWidth } from '../settings';
import SiderMenu from '../components/siderMenu';
import { componentLoader } from '../utils';
import { resetSocket, setSocket, socket } from "../utils/socket";
import { socketUrl } from '../settings'

const NotFound = lazy(() => componentLoader(() => import('../components/Exception/404')))
const DashboardLayout = lazy(() => componentLoader(() => import('./dashboardLayout')))
const { Content } = Layout;

const keysForStore = ['store', 'productCategory', 'product']
export default (props) => {
    const isMobile = useMediaQuery({ maxWidth: mobileMaxWidth })
    const isDesktop = useMediaQuery({ maxWidth: desktopMaxWidth })

    const { children, item } = props

    const dispatch = useDispatch()
    const params = useParams()
    const [showException, setShowException] = useState(false)
    const [collapsed, setCollapsed] = useState(false)
    const { shop, currentUser } = useSelector((state) => ({
        shop: state.global.shop,
        currentUser: state.global.currentUser
    }))

    useEffect(() => {
        if (params.id && keysForStore.includes(item.key)) {
            request.getProfile(params.id)
                .then(({ data, error, message }) => {
                    if (!error) {
                        if (shop?.user_id !== data?.user_id) {
                            dispatch({ type: 'CLEAR_CART' })
                        }
                        setShowException(() => false)
                        dispatch({
                            type: 'SET_CURRENT_SHOP',
                            payload: { ...data }
                        })
                        // dispatch({
                        //     type: 'SET_DELIVERY_CHARGES',
                        //     payload: data?.deliveryFees
                        // })
                    }
                    else {
                        setShowException(() => message)
                        dispatch({
                            type: 'SET_CURRENT_SHOP',
                            payload: null
                        })
                    }
                })
        }
    }, [window.location.pathname])

    useEffect(() => {
        if (!socket && currentUser) {

            const io = new WebSocket(socketUrl + `/${currentUser?.user}/`);
            setSocket(io)
            io.onopen = function (event) {
                io.onmessage = function (event) {
                    dispatch({ type: 'SHOW_NOTIFICATION', payload: JSON.parse(event.data), dispatch })
                    dispatch({ type: 'SOCKET_REFRESH', payload: JSON.parse(event.data) })
                    if (window.location.pathname !== "/checkout") {
                        window.location.reload()
                    }
                };

                // Listen for socket closes
                io.onclose = function (event) {
                    console.log('socket connect', JSON.parse(event.data))
                    if (event.type === "close" && window.location.pathname !== "/checkout") {
                        window.location.reload()
                    }
                    resetSocket()
                };

                // To close the socket....
                //socket.close()

            };
        }
    }, [currentUser])

    const toggle = () => {
        setCollapsed((prev) => !prev)
    }
    return (
        showException ?
            <div className='flex' style={{ height: '100vh' }}>
                <NotFound message={showException} />
            </div> :
            <Layout>
                <Layout className={'layout'}>
                    <DashboardHeader collapsed={collapsed} toggle={toggle} isMobile={isMobile} isDesktop={isDesktop} item={item} />
                    {isMobile && <DashboardLayout><SiderMenu item={item} /></DashboardLayout>}
                    <Content
                        className={'content'}
                    >
                        {children}
                    </Content>
                </Layout>
            </Layout>
    );
}