import React from 'react';
import './dashboardLayout.less'
import { LeftOutlined } from '@ant-design/icons'
import { goBack } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import _ from 'lodash'

const HeadTitleLayout = (props) => {
    const dispatch = useDispatch()
    const { title, isBack, onBack, isMobile } = props

    return (
        <div className="headTitleLayout">
            {title && <div className='flex pageHeaderTitle'>
                {isBack && <LeftOutlined className='backIcon' style={isMobile ? {marginRight: '0.5rem'} : { marginRight: '1rem' }} onClick={() => {
                    onBack ? onBack() :
                        dispatch(goBack())
                }} />}
                {title}
            </div>}
        </div>
    );

}

export default HeadTitleLayout;